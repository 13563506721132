import React from 'react';
// import { Button } from './Button';
import './Footer.css';
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <div className="footer-container">
            {/* <section className="footer-subscription">
                <p className="footer-subscription-heading">
                    Join our newsletter for fantastic updates, right to your inbox!
                </p>
                <div className="input-areas">
                    <form>
                        <input type="email" name="email" placeholder="Enter your email" className="footer-input" />
                        <Button buttonStyle="btn--outline">Subscribe</Button>
                    </form>
                </div>
            </section> */}
            <div class='footer-links'>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>About Us</h2>
            <Link to='/about'>About Cake it Easy</Link>
            <Link to='/gallery'>Our Creations</Link>
          </div>
          <div class='footer-link-items'>
            <h2>Contact Us</h2>
            <Link to='/contact'>Contact</Link>
          </div>
        </div>
        <div className='footer-link-wrapper'>
          
          <div class='footer-link-items'>
            <h2>Social Media</h2>
            <a href="https://www.instagram.com/cake.it.easyx/">Instagram</a>
            <a href="https://www.facebook.com/cakeiteasy.mi">Facebook</a>
          </div>
        </div>
      </div>
      <section class='social-media'>
        <div class='social-media-wrap'>
          <div class='footer-logo'>
            <Link to='/' className='social-logo'>
              CAKE IT EASY
            </Link>
          </div>
          <small class='website-rights'>CAKE IT EASY © 2021</small>
          <small class='website-rights'>DESIGNED BY DFS DESIGN STUDIO LLC</small>
          <div class='social-icons'>
            <Link
              class='social-icon-link facebook'
              to={{ pathname: "https://www.facebook.com/cakeiteasy.mi"}}
              target='_blank'
              aria-label='Facebook'
            >
              <i class='fab fa-facebook-f' />
            </Link>
            <Link
              class='social-icon-link instagram'
              to={{ pathname: "https://www.instagram.com/cake.it.easyx/"}}
              target='_blank'
              aria-label='Instagram'
            >
              <i class='fab fa-instagram' />
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer
