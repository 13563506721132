import React from 'react';
import '../App.css';
import './AboutSection.scss';

function AboutSection () {
    return (

        <>
        <div id='aboutPage'>
            <h1>
                About Cake It Easy
            </h1>

            <p className="subtitle">
                We love our work, and you should too!
            </p>

            <div id="about-container">
                <div className="about-info">

                <h4>
                    Social Media
                </h4>

                <p>See updates that might not be posted here!</p>

                    <div className="social-media">
                        <div className="icon-circle">
                            <a href="https://www.facebook.com/cakeiteasy.mi">
                                <i class="fab fa-facebook-f" aria-hidden="true"></i>
                            </a>
                        </div>
                
                        <div className="icon-circle">
                            <a href="https://www.instagram.com/cake.it.easyx/">
                                <i class="fab fa-instagram" aria-hidden="true"></i>
                            </a>
                        </div>
                    </div>
                </div>
            
            


                <div id="about-form-wrapper">
                    <form id="aboutformwrap">
                        <div className="aboutcol">
                            <div className="about-form-group">
                                <label>
                                    Here at Cake it Easy, we were started by three sisters
                                    who had the dream to provide a product that they loved to
                                    others. We also all loved making desserts, especially tasting
                                    them, and thus we began our journey providing others with
                                    the great tastes we have been having fun making. Starting in
                                    2020 was the perfect time for us to hone our skills and craft
                                    into the wonderful delight it is now. Please see our gallery
                                    for some of the phenomenal creations we have made for multiple
                                    satisfied clientele. 
                                </label>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        </>
    )
}

export default AboutSection