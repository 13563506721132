import React from 'react';
import Navbar from './components/Navbar';
import {BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.css';
import Home from './components/pages/Home';
import Contact from './components/pages/Contact';
import Gallery from './components/pages/Gallery';
import About from './components/pages/About';
import Menu from './components/pages/Menu';
import Thanks from './components/pages/Thanks';

function App() {
  return (
    <>
    <Router>
      <Navbar />
      <Switch>
        <Route path='/' exact component={Home} />
        <Route path='/gallery' exact component={Gallery} />
        <Route path='/about' exact component={About} />
        <Route path='/contact' exact component={Contact} />
        <Route path='/menu' exact component={Menu} />
        <Route path='/thanks' exact component={Thanks} />
      </Switch>
      </Router>
    </>
  );
}

export default App;
