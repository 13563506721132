import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import GalleryCards from '../GalleryCards.js';

export default function Gallery() {
    return (

        <>
                <GalleryCards />
                <Footer />
        </>


    )
}