import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import ThanksSection from '../ThanksSection';

export default function Thanks() {
    return (

        <>
        <ThanksSection />
        <Footer />
</>

    )
}