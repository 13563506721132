import React from 'react';
import '../App.css';
import './MenuSection.scss';
import menu from "./images/img-menu.png";

function MenuSection () {
    return (

        <>
        <div id='menuPage'>
            <h1>
                MENU
            </h1>

            <p className="subtitle">
                We know you'll want one of each.
            </p>

            <div className="imagewrapper">
                <div className="img">
                    <img src={menu} alt="menu" />
                </div>
            </div>
        </div>
        </>
    )
}

export default MenuSection