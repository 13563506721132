import React from 'react';
import './ContactForm.scss';


function ContactForm() {

  return (
    
    <>
        <div id='contactForm'>

            <h1>
                Contact Us
            </h1>

            <p className="subtitle">
                Questions? Want to Order? Complete the form below!
            </p>

        <div id="contact-container">
            <div className="contact-info">

            <h4>
                Contact Information
            </h4>

            <p>Fill out the form and click submit!</p>

                <div className="icon-text">
                    <i class="fas fa-phone" aria-hidden="true"></i>
                    <span>313-466-0471</span>
                </div>

                <div className="icon-text">
                    <i class="fas fa-mail-bulk" aria-hidden="true"></i>
                    <span>cakeiteasy.mi@gmail.com</span>
                </div>
            
                <div className="icon-text">
                    <i class="fas fa-location-arrow" aria-hidden="true"></i>
                    <span>Southern Michigan</span>
                </div>
            
                <div className="social-media">
                    <div className="icon-circle">
                        <a href="https://www.facebook.com/cakeiteasy.mi">
                            <i class="fab fa-facebook-f" aria-hidden="true"></i>
                        </a>
                    </div>
                
                    <div className="icon-circle">
                        <a href="https://www.instagram.com/cake.it.easyx/">
                            <i class="fab fa-instagram" aria-hidden="true"></i>
                        </a>  
                    </div>
                </div>
            </div>
                
            <div id="form-wrapper">
                <div id="contactformwrap">
                <form name="contactUs" method="POST" data-netlify="true" action="/thanks">
                    <input type="hidden" name="form-name" value="contactUs" />
                    <div className="col">
                        <div className="form-group">
                            <label>First Name
                            <input type="text" name="firstName" /></label>
                        </div>

                        <div className="form-group">
                            <label>Last Name
                            <input type="text" name="lastName" /></label>
                        </div>

                    </div>

                    <div className="col">

                        <div className="form-group">
                            <label>E-Mail
                            <input type="email" name="email" /></label>
                        </div>

                        <div className="form-group">
                            <label>Phone #
                            <input type="tel" name="phone" /></label>
                        </div>

                    </div>

                    <div className="col">
                        <div className="form-group solo">
                            <label>What delicious treat were you looking for?</label>

                            <div id="radio-buttons">
                                <div className="radio-button">
                                    <label><input type="radio" name="service" value="strawberries" /> Strawberries</label></div>
                                <div className="radio-buttons">
                                    <label><input type="radio" name="service" value="tower" /> Strawberry Tower</label></div>
                                <div className="radio-buttons">
                                    <label><input type="radio" name="service" value="cakepops" /> Cake Pops</label></div>
                                <div className="radio-buttons">
                                    <label><input type="radio" name="service" value="shooters" /> Shooters</label></div>
                                <div className="radio-buttons">
                                    <label><input type="radio" name="service" value="other" /> Multiple/Other</label></div>
                            </div>

                        </div>
                    </div>
                

                    <div className="col">
                        <div className="form-group solo">
                            <label>Describe your vision to us
                            <textarea name="message"></textarea></label>
                        </div>
                    </div>

                    <div className="col">
                        <div className="form-group solo right">
                            <button className="primary" type="submit">Submit</button>
                        </div>
                    </div>
                    
                </form>
                </div>
            </div>
            
        </div>
        </div>
        
    </>

  )
}

export default ContactForm;