import React from 'react';
import '../App.css';
import './ThanksSection.scss';

function ThanksSection () {
    return (

        <>
        <div id='thanksPage'>
            <h1>
                Thank You!
            </h1>

            <p className="subtitle">
                We have received your request
            </p>

            <div id="about-container">
                <div className="about-info">

                <h4>
                    Social Media
                </h4>

                <p>See updates that might not be posted here!</p>

                    <div className="social-media">
                        <div className="icon-circle">
                            <a href="https://www.facebook.com/cakeiteasy.mi">
                                <i class="fab fa-facebook-f" aria-hidden="true"></i>
                            </a>
                        </div>
                
                        <div className="icon-circle">
                            <a href="https://www.instagram.com/cake.it.easyx/">
                                <i class="fab fa-instagram" aria-hidden="true"></i>
                            </a>
                        </div>
                    </div>
                </div>
            
            


                <div id="about-form-wrapper">
                    <form id="aboutformwrap">
                        <div className="aboutcol">
                            <div className="about-form-group">
                                <label>
                                    Thank you for contacting! We will get back to you as soon as possible!
                                    Please call if this order is on a rush basis, find out contact information 
                                    on the Contact page.
                                </label>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        </>
    )
}

export default ThanksSection