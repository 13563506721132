import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import ContactForm from '../ContactForm';

export default function Contact() {
    return (
        <>
        <ContactForm />
        <Footer />
        </>
    );
}