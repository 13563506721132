import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import MenuSection from '../MenuSection';

export default function Menu() {
    return (

        <>
        <MenuSection />
        <Footer />
</>

    )
}