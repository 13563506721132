import React from 'react';
import '../../App.css';
import CakeSection from '../CakeSection';
import Cards from '../Cards';
import Footer from '../Footer';

export default function Home () {
    return (
        <>
        <CakeSection />
        <Cards />
        <Footer />
        </>
    );
}
