import React from 'react';
import CardItem from "./CardItem";
import "./Cards.css";

function Cards() {
    return (
        <div className="cards">
            <h1>Check out our LOVELY desserts!</h1>
            <div className="cards__container">
                <div className="cards__wrapper">
                    <ul className="cards__items">
                        <CardItem 
                        src="images/assortmentcard.jpg"
                        text="Beautiful composition of strawberries"
                        label="Strawberries"
                        path="/menu"
                        />
                        <CardItem 
                        src="images/sbtower.jpg"
                        text="Colorful strawberry tower!"
                        label="Strawberries"
                        path="/menu"
                        />
                    </ul>
                    <ul className="cards__items">
                        <CardItem 
                        src="images/chocsb.jpg"
                        text="All lined up! Strawberries ready to go!"
                        label="Strawberries"
                        path="/menu"
                        />
                        <CardItem 
                        src="images/cakepops-1.jpg"
                        text="Lovely, stylish cake pops"
                        label="Cake Pops"
                        path="/menu"
                        />
                        <CardItem 
                        src="images/shooters.jpg"
                        text="Shooters: a classic take on a fun dessert"
                        label="Shooters"
                        path="/menu"
                        />
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Cards;
