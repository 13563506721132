import React from 'react';
import '../App.css';
import { Button } from './Button';
import { Link } from 'react-router-dom'
import './CakeSection.scss';

function CakeSection () {
    return (
        <div id='hero-container'>
            <h1>CAKE IT EASY</h1>
            <p id="subtitle">Elegance, beauty, tasteful</p>
            
            <div className="hero-btns">
            <Link to='/menu'>
                <Button 
                className='btns' 
                buttonStyle='btn--outline'
                buttonSize='btn--large'>
                    VIEW OUR MENU
                </Button>
            </Link>

            <Link to='/contact'>
                <Button 
                className='btns' 
                buttonStyle='btn--primary'
                buttonSize='btn--large'>
                    CONTACT US <i className='far fa-address-book' />
                </Button>
            </Link>

            </div>
        </div>
    );
}

export default CakeSection;