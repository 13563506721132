import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import { Button } from './Button';
import './Navbar.scss';
import logo from './images/logo50x50.png';

function Navbar() {
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);

    const handleClick = () => setClick(!click);

    const closeMobileMenu = () => setClick(false);


    var windowWidth = window.innerWidth

    // Resize Event
    window.addEventListener("resize", function() {

        // Check window width has actually changed and it's not just iOS triggering a resize event on scroll
        if (window.innerWidth !== windowWidth) {

            // Update the window width for next time
            windowWidth = window.innerWidth

            showButton();

        }

        // Otherwise do nothing
    });


    const showButton = () => {
        if(window.innerWidth <= 960) {
            setButton(false);
        }
        else {
            setButton(true);
        }
    };

    useEffect( () => {
        showButton();
    }, []);

    

    // window.addEventListener('resize', showButton);

    return (
        <>
        <nav id="navbar">
            <div className="navbar-container">
                <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
                    <img src={logo} alt="logo" />
                </Link>
                <div className="menu-icon" onClick={handleClick}>
                    <i className ={click ? 'fas fa-times' : 'fas fa-bars'} />
                </div>
                <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                    <li className='nav-item'>
                        <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                            Home
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/gallery' className='nav-links' onClick={closeMobileMenu}>
                            Gallery
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/about' className='nav-links' onClick={closeMobileMenu}>
                            About
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/contact' className='nav-links' onClick={closeMobileMenu}>
                            Contact
                        </Link>
                    </li>
                    <li>
                        <Link to='/menu' className='nav-links-mobile' onClick={closeMobileMenu}>
                            View Our Menu
                        </Link>
                    </li>
                </ul>
                {button && <Button buttonStyle='btn--outline'>View Our Menu</Button>}
            </div>
        </nav>
        
        </>
    )
}

export default Navbar
