import React from 'react';
import GalCardItem from "./GalCardItem";
import "./GalleryCards.scss";

function GalleryCards() {
    return (
        <div id="galcards">
            <h1>View Our Gallery Of Beauty!</h1>
            <div className="cards__container">
                <div className="cards__wrapper">
                <ul className="cards__items">
                        <GalCardItem 
                        src="images/assortment.jpg"
                        text="We do large assortments of desserts for any occasion!"
                        /><br />
                        <GalCardItem 
                        src="images/fourthpops.jpg"
                        text="Memorial Day themed cake pops"
                        /><br />
                        <GalCardItem 
                        src="images/fourthshooter.jpg"
                        text="Memorial Day themed shooters"
                        />
                    </ul>

                    <ul className="cards__items">
                        <GalCardItem 
                        src="images/assortmentcard.jpg"
                        text="Beautiful composition of strawberries"
                        /><br />
                        <GalCardItem 
                        src="images/sbtower3.jpg"
                        text="Colorful strawberry tower!"
                        /><br />
                        <GalCardItem 
                        src="images/sbtower2.jpg"
                        text="Lovely mini strawberry tower!"
                        />
                    </ul>
                    
                    <ul className="cards__items">
                        <GalCardItem 
                        src="images/raspsquares.jpg"
                        text="These raspberry squares still make us say wow!"
                        /><br />
                        <GalCardItem 
                        src="images/cakepops-2.jpg"
                        text="Colorful, fun themed cake pops"
                        /><br />
                        <GalCardItem 
                        src="images/cakepops-3.jpg"
                        text="Decadent cake pops for a classy occasion"
                        />
                    </ul>
                    <ul className="cards__items">
                        <GalCardItem 
                        src="images/chocsb.jpg"
                        text="All lined up! Strawberries ready to go!"
                        /><br />
                        <GalCardItem 
                        src="images/cakepops-1.jpg"
                        text="Lovely, stylish cake pops"
                        /><br />
                        <GalCardItem 
                        src="images/shooters.jpg"
                        text="Shooters: a fun take on a classic dessert"
                        />
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default GalleryCards;
